import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/TLCLayout"
import { MDBRow, MDBCol, MDBView, MDBMask, MDBContainer } from "mdbreact"
import Img from "gatsby-image"

import QuickContactForm from '../components/QuickContactForm'
// import { Z_BLOCK } from "zlib"
// import { nonExecutableDefinitionMessage } from "graphql/validation/rules/ExecutableDefinitions"

export default class GalleryPage extends React.Component {
    constructor() {
        super()
        this.state = {
            currentLightbox: 'none'
        }
    }

    showImage = (e, i) => {
        this.setState({currentLightbox: i})
    }
    
    render() {
        //Styles
        var lightboxStyle = {
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',            
            zIndex: '101',
            backgroundColor: 'rgba(0,0,0,0.75)',
            paddingTop: '150px',
            // visibility: 'hidden',
            display: 'none',
            transition: 'opacity 0.5s, visibility 0s 0.5s',
        }

        let galleryRows = []
        let galleryImages = []
        let lightboxes = []
        const pageLinks = []

        for (let i=0; i < this.props.data.allMarkdownRemark.pageInfo.itemCount; i++){
            galleryImages.push(
                <MDBCol style={{padding: 0}}>
                    <div>
                        <MDBView hover zoom onClick={(e) => this.showImage(e, i)}>
                            <Img fluid={this.props.data.allMarkdownRemark.edges[i].node.frontmatter.image.childImageSharp.fluid} />                        
                            <MDBMask className="flex-center" overlay="black-strong">
                                <p className="white-text" style={{fontSize: '5em'}}><i class="fas fa-search-plus"></i></p>
                            </MDBMask>                    
                        </MDBView>
                    </div>
                </MDBCol>                    
            )

            lightboxes.push(
                <MDBContainer 
                    onClick={(e) => this.setState({currentLightbox: 'none'})}
                    style={lightboxStyle} className={(this.state.currentLightbox === i) ? 'd-block' : 'd-none'} 
                    fluid
                >
                    <MDBContainer style={{maxWidth: '1000px'}}>
                        <Img fluid={this.props.data.allMarkdownRemark.edges[i].node.frontmatter.image.childImageSharp.fluid} />
                    </MDBContainer>
                </MDBContainer>
            )

            if(((i+1)%3 === 0) || i+1 === this.props.data.allMarkdownRemark.pageInfo.itemCount){
                galleryRows.push(
                    <MDBRow>
                        {galleryImages}
                    </MDBRow>
                )

                galleryImages = []
            }
        }     

        // Build Pagination Links
        var inactivePageLinkStyle = {
            display: 'block',
            paddingLeft: '1em',
            paddingRight: '1em',
            margin: '0.5em',
            backgroundColor: '#eee',
            border: '1px solid #888',
            color: '#888',
        }

        var activePageLinkStyle = {
            display: 'block',
            paddingLeft: '1em',
            paddingRight: '1em',
            margin: '0.5em',
            backgroundColor: '#eee',
            border: '1px solid #888',
            color: '#333',
            fontWeight: 400,            
        }

        let currentPage = this.props.data.allMarkdownRemark.pageInfo.currentPage
        let hasPreviousPage = this.props.data.allMarkdownRemark.pageInfo.hasPreviousPage
        let hasNextPage = this.props.data.allMarkdownRemark.pageInfo.hasNextPage

        // Set up previous link
        if(hasPreviousPage) {
            if (parseInt(currentPage) - 1 === 1)
                pageLinks.push(<Link to={'/gallery/'} style={inactivePageLinkStyle}>{'<'}</Link>)
            else
                pageLinks.push(<Link to={(hasPreviousPage) ? '/gallery/' + (parseInt(currentPage) - 1) : '/gallery/' + currentPage} style={inactivePageLinkStyle}>{'<'}</Link>)
        }
        // Add numbered links
        for (let i=1; i <= this.props.data.allMarkdownRemark.pageInfo.pageCount; i++){
            if(i===1)
                pageLinks.push(<Link to="/gallery" style={(currentPage === i) ? activePageLinkStyle : inactivePageLinkStyle}>{i}</Link>)
            else
                pageLinks.push(<Link to={"/gallery/" + i} style={(currentPage === i) ? activePageLinkStyle : inactivePageLinkStyle}>{i}</Link>)
        }
        // Set up next link
        if(hasNextPage) {
            pageLinks.push(<Link to={(hasNextPage) ? '/gallery/' + (parseInt(currentPage) + 1) : '/gallery/' + currentPage} style={inactivePageLinkStyle}>{'>'}</Link>)
        }

        return (
            <Layout heroTitle="Gallery" heroImageUrl="./images/hero-dark.jpg">
                <MDBContainer>                    
                    <MDBRow>
                        <MDBCol md="12">
                            <div className="d-flex justify-content-center">{pageLinks}</div>
                            {galleryRows}
                            <div className="d-flex justify-content-center">{pageLinks}</div>
                        </MDBCol>
                        {/* <MDBCol md="5">
                            <QuickContactForm />
                        </MDBCol> */}
                    </MDBRow>
                </MDBContainer>
                {lightboxes}
            </Layout>
        )
    }
}

export const blogListQuery = graphql`
    query GalleryPageQuery ($skip: Int!, $limit: Int!) {
        allMarkdownRemark(
            limit: $limit
            skip: $skip
            sort: {fields: frontmatter___date, order: ASC}
        ){
            edges {
                node {
                    frontmatter {
                        title
                        image {
                            relativePath
                            childImageSharp {
                                fluid(maxWidth: 1080) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }                                   
                    }
                }
            }
            pageInfo {
                perPage
                pageCount
                itemCount
                hasPreviousPage
                hasNextPage
                currentPage
            }
        }
    }
`